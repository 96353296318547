import request from '@/utils/request'

// 优惠券详情
export function queryCoupon(couponId) {
   return request({
        url: 'open/sinoepc/1.0/coupon/' + couponId,
        method: 'get'
    })
}

// 发送优惠券
export function applyCoupon(data) {
   const params = {
           couponId: data.couponId === undefined ? '' : data.couponId,
           openId: data.openId === undefined ? '' : data.openId,
           count: data.count === undefined ? 1 : data.count
   };
    return request({
        url: 'open/sinoepc/1.0/coupon/apply',
        method: 'post',
        data: params
    })
}
